import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function updateExtensionSettings({ clinicId, extensionPatientSolicitation, extensionPatientSolicitationSurveyId }) {
  try {
    const res = await axios.put(
      `/clinics/${clinicId}/update_extension_settings`,
      {
        clinic_setting: {
          extension_patient_solicitation_survey_id: extensionPatientSolicitationSurveyId,
          extension_patient_solicitation: extensionPatientSolicitation,
        },
      },
      { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } },
    );
    return res;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
